import React from 'react';
import './css/App.css';
import { TailSpin } from 'react-loader-spinner'
import Nav from './Nav';
import Facial from './Facial';
import Clave from './Clave';
//const apiBaseUrl = 'https://pre.datagestion.net/datagest/api/v1.0/controlhorario/'
const apiBaseUrl = 'https://plataforma.datagestion.net/datagest/api/v1.0/controlhorario/'
class App extends React.Component {

  constructor() {
    super();
    this.state = {
      loading: true,
      companyName: '',
      idCompany: 0,
    }
  }

  componentDidMount() {
    var url = window.location.pathname.replace(/\//g, "")
    url = apiBaseUrl + 'checkURL/' + url
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => {
        if (response === undefined) {
          window.location.href = 'https://datagestion.net'
        }
        else {
          let clockingType = "CLAVE"
          if (response.isControlHorario) {
            clockingType = "FACIAL";
          }
          this.setState({
            companyName: response.razonSocial,
            idCompany: response.idCompany,
            clockingType: clockingType,
            loading: false
          })
        }
      });
  }

  stateValueSetter = (key, value) => {
    let state = this.state;
    state[key] = value
    this.setState(state);
  }

  clockingComponent() {
    if (this.state.clockingType === "FACIAL") {
      return (
        <Facial
          streamWidth={320}
          streamHeight={400}
          apiBaseUrl={apiBaseUrl}
          idCompany={this.state.idCompany}
          stateValueSetter={this.stateValueSetter}
        />
      )
    } else {
      return (
        <Clave
          apiBaseUrl={apiBaseUrl}
          idCompany={this.state.idCompany}
          stateValueSetter={this.stateValueSetter}
        />
      )
    }

  }

  render() {
    if (this.state.loading) {
      return (
        <div className="App">
          <header className="App-body">
            <TailSpin
              className="loading-spinner"
              color="#00BFFF"
              height={200}
              width={200}
              timeout={0} //3 secs
            ></TailSpin>
            <small className="smallForm">Copyright Datagestión © 2015 - 2022</small>
          </header>
        </div >
      )
    }
    return (
      <div className="App">
        <Nav />
        <header className="App-body">
          <h1 className="title">{this.state.companyName}</h1>
          <div className='text-white rounded'>
            {this.clockingComponent()}
          </div>
          <small className="smallForm">Copyright Datagestión © 2015 - 2022</small>
        </header >
      </div >
    );
  }
}

export default App;
