import React from 'react';
import { createRoot } from 'react-dom/client';
import './css/index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<div><Nav /><BrowserRouter><App /></BrowserRouter></div>, document.getElementById('root'));
const container = document.getElementById('root')
const root = createRoot(container)
root.render(<BrowserRouter><App /></BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
