import { Component } from "react";
import { Button, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import Clock from 'react-live-clock';

const MySwal = withReactContent(Swal.mixin({
    confirmButtonColor: "#0084FB",
}))

export default class Clave extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idCompany: this.props.idCompany,
            apiBaseUrl: this.props.apiBaseUrl,
            parentStateValueSetter: this.props.stateValueSetter,
            clave: "",
        }
    }

    fichajeClave = (e) => {
        const time = new Date()
        this.state.parentStateValueSetter("loading", true);
        //const swalButtonsState = MySwal.fire({
        MySwal.fire({
            title: <p style={{ textAlign: "center" }} >Realizando fichaje</p>,
            text: "Fichando ...",
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
        MySwal.showLoading();

        const formData = new FormData();
        formData.append("nifWorker", this.state.clave);
        formData.append("idCompany", this.state.idCompany);
        const url = this.state.apiBaseUrl + 'checkWithClave/'
        fetch(url, {
            method: 'POST',
            body: formData,
            //headers: {
            //    'Accept': 'application/json',
            //    'Content-Type': 'application/json'
            //}
        }).then(res => {
            MySwal.hideLoading();
            if (res.status === 200) {
                res.text().then(res_text => {
                    const res_json = JSON.parse(res_text)
                    const name = res_json.name;
                    const tipo_fichaje = res_json.tipoFichaje;
                    function addZero(i) { return i < 10 ? "0" + i : i; }
                    const horaFichaje = addZero(time.getHours()) + ":" + addZero(time.getMinutes()) + ":" + addZero(time.getSeconds());
                    const title = <p style={{ textAlign: "center" }}>Fichaje correcto</p>
                    const text = name + ' ha realizado un fichaje de ' + tipo_fichaje + " a las: " + horaFichaje;
                    MySwal.update({
                        title: title,
                        text: text,
                        icon: 'success'
                    })
                })
            }
            else {
                const title = <p style={{ textAlign: 'center' }}>Fichaje incorrecto</p>
                const text = "Su fichaje no se ha podido realizar, compruebe su clave o póngase en contacto con su administrador";
                MySwal.update({
                    title: title,
                    text: text,
                    icon: 'error',
                })
            }
        }).catch(error => {
            const title = <p style={{ textAlign: 'center' }}>Fichaje incorrecto</p>
            const text = "Su fichaje no se ha podido realizar, compruebe su clave o póngase en contacto con su administrador";
            MySwal.update({
                title: title,
                text: text,
                icon: 'error',
            })
        })
        this.setState({ clave: "" });
        this.state.parentStateValueSetter("loading", false);
    }

    handleClave = (e) => {
        this.setState({ clave: e.target.value })
    }

    render() {
        const clave = this.state.clave;
        return (
            <div>
                <p className="labelForm" htmlFor="exampleEmail">Introduce tu clave:</p>
                <Input type="text" className="inputClave" value={clave} onChange={this.handleClave} id="exampleEmail" placeholder="Clave" />
                <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <Clock className="time-label" format={'DD/MM/YYYY HH:mm:ss'} ticking={true} timezone={'Europe/Madrid'} />
                </div>
                <Button onClick={this.fichajeClave} disabled={!clave} className="btnForm" color="success">Fichar</Button>
            </div>
        )
    }
}