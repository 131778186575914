import React from 'react';
import PDF from './assets/documents/manual.pdf';
import logo from './assets/letras.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      isWrap: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  onEntering() {
    this.setState({
      isWrap: true
    });
  }
  onExited() {
    this.setState({
      isWrap: false
    });
  }
  render() {
    const { isOpen, isWrap } = this.state;
    return (
      <div>
        <Navbar color="faded" light expand="md" style={{ flexWrap: isWrap ? "wrap" : "nowrap" }}>
          <NavbarToggler onClick={() => this.toggle()} />
          <NavbarBrand className="navControl" href="/"><img width="100px" src={logo} alt="Logo" /><span className="orangeColor"> | </span>Control Horario</NavbarBrand>
          <Collapse isOpen={isOpen} onEntering={() => this.onEntering()} onExited={() => this.onExited()} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href={PDF} target='_blank' >¿Cómo funciona?</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.datagestion.net/index.php/politica-de-privacidad/" target='_blank'>Política de Privacidad</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div >
    );
  }
}