import { Component, createRef } from "react";
import { Button } from "reactstrap";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Clock from 'react-live-clock';
import CameraStream from "./CameraStream";

const MySwal = withReactContent(Swal.mixin({
    confirmButtonColor: "#0084FB",
    confirmButtonText: "Ok",
    denyButtonColor: "#FF5D00",
}))

export default class Facial extends Component {
    constructor(props) {
        super(props);
        this.cameraStreamRef = createRef()
        this.videoRef = createRef()

        //const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
        //    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
        //    !window.MSStream

        const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel')) &&
            !window.MSStream

        this.state = {
            apiBaseUrl: this.props.apiBaseUrl,
            idCompany: this.props.idCompany,
            parentStateValueSetter: this.props.stateValueSetter,
            userPicture: null,
            isIOS: isIOS
        }
    }

    stateValueSetter = (key, value) => {
        let state = this.state;
        state[key] = value;
        this.setState(state);
    }

    fichajeFacialNuevo = (evt, isIOS) => {
        const time = new Date()
        this.state.parentStateValueSetter("loading", true);
        const cameraStream = this.cameraStreamRef.current;
        cameraStream.captureCameraPhoto().then(({ userPicture, canvas, imageUri }) => {
            let imageUrl = canvas.toDataURL();
            //let imageHeight = 400;
            let imageHeight = 240;
            if (isIOS) {
                imageUrl = imageUri;
                imageHeight = "auto"
            }
            const swalButtonsState = MySwal.fire({
                title: <p style={{ textAlign: "center" }} >Realizando fichaje</p>,
                text: "Fichando ...",
                imageUrl: imageUrl,
                imageHeight: imageHeight,
                //imageWidth: 320,
                imageWidth: 192,
                allowOutsideClick: false,
                allowEscapeKey: false,
            })
            MySwal.showLoading();
            //console.log("userPicture: ", userPicture)
            const formData = new FormData();
            userPicture.lastModifiedDate = new Date();
            userPicture.name = "userPicture.jpg";
            formData.append("userPicture", userPicture);
            formData.append("idCompany", this.state.idCompany.toString());
            const url = this.state.apiBaseUrl + 'CheckWithFacialRecognitionServlet';
            fetch(url, {
                method: "POST",
                //headers: {
                //'Content-Type': 'multipart/form-data'
                //},
                body: formData,
            }).then(res => {
                MySwal.hideLoading();
                if (res.status === 200) { //Se ha fichado
                    res.text().then(res_text => {
                        const res_json = JSON.parse(res_text)
                        const name = res_json.name;
                        const tipoFichaje = res_json.tipoFichaje
                        function addZero(i) { return i < 10 ? "0" + i : i; }
                        const horaFichaje = addZero(time.getHours()) + ":" + addZero(time.getMinutes()) + ":" + addZero(time.getSeconds());
                        const title = <p style={{ textAlign: "center" }}><i className="fa fa-solid fa-check" style={{ color: "green" }} /> Fichaje correcto</p>
                        const text = name + ' ha realizado un fichaje de ' + tipoFichaje + ' a las: ' + horaFichaje;
                        MySwal.update({
                            title: title,
                            text: text,
                            //icon: "success",
                        })
                    })
                } else if (res.status === 400) { //Fotografía no válida
                    const title = <p style={{ textAlign: 'center' }}><i className="fa fa-solid fa-ban" style={{ color: "red" }} /> Fichaje incorrecto</p>
                    const text = "Fotografía no válida";
                    MySwal.update({
                        title: title,
                        text: text,
                        //icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: "Reintentar",
                        showDenyButton: true,
                        denyButtonText: "Fichar con clave",
                    })
                    swalButtonsState.then((result) => {
                        if (result.isDenied) {
                            this.state.parentStateValueSetter("clockingType", "CLAVE");
                        }
                    })
                } else if (res.status === 417) { //Usuario no reconocido
                    const title = <p style={{ textAlign: 'center' }}><i className="fa fa-solid fa-ban" style={{ color: "red" }} /> Fichaje incorrecto</p>
                    const text = "No se reconoce al usuario";
                    MySwal.update({
                        title: title,
                        text: text,
                        //icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: "Reintentar",
                        showDenyButton: true,
                        denyButtonText: "Fichar con clave",
                    })
                    swalButtonsState.then((result) => {
                        if (result.isDenied) {
                            this.state.parentStateValueSetter("clockingType", "CLAVE");
                        }
                    })
                } else {
                    const title = <p style={{ textAlign: 'center' }}><i className="fa fa-solid fa-ban" style={{ color: "red" }} /> Fichaje incorrecto</p>
                    const text = "Ha ocurrido un error al realizar el fichaje";
                    MySwal.update({
                        title: title,
                        text: text,
                        //icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: "Reintentar",
                        showDenyButton: true,
                        denyButtonText: "Fichar con clave",
                    })
                    swalButtonsState.then((result) => {
                        if (result.isDenied) {
                            this.state.parentStateValueSetter("clockingType", "CLAVE");
                        }
                    })
                }
            })
            this.state.parentStateValueSetter("loading", false);

        });
    }

    render() {
        const { isIOS } = this.state;
        return (
            <div>
                <CameraStream
                    ref={this.cameraStreamRef}
                    videoRef={this.videoRef}
                    width={this.props.streamWidth}
                    height={this.props.streamHeight}
                    stateValueSetter={this.stateValueSetter}
                />
                <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <Clock className="time-label" format={'DD/MM/YYYY HH:mm:ss'} ticking={true} timezone={'Europe/Madrid'} />
                </div>
                <Button onClick={(evt) => this.fichajeFacialNuevo(evt, isIOS)} className="btnForm" color="success">Fichar</Button>
            </div >
        )
    }
}