import React, { Component } from "react";
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from "jslib-html5-camera-photo";

export default class CameraStream extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentStateValueSetter: this.props.stateValueSetter,
            videoRef: this.props.videoRef,
        }
    }

    componentDidMount() {
        const video = document.getElementById("mivideo");
        let cameraPhoto = new CameraPhoto(video);
        cameraPhoto.startCamera(FACING_MODES.USER, { width: 1280, height: 720 })
        let state = this.state;
        state.cameraPhoto = cameraPhoto;
        this.setState({ state })
    }

    async captureCameraPhoto() {
        let video = document.getElementById("mivideo");
        const userPictureHeight = video.videoHeight;
        const userPictureWidth = Math.round(userPictureHeight * 0.8);
        const horizontalSkip = Math.round((video.videoWidth - userPictureWidth) / 2);
        //get picture:
        let dataUri = await this.state.cameraPhoto.getDataUri({ imageType: IMAGE_TYPES.JPG })
        //get canvas:
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = userPictureWidth;
        canvas.height = userPictureHeight;
        const image = new Image()
        image.onload = function () {
            ctx.drawImage(
                image,
                horizontalSkip,
                0,
                userPictureWidth,
                userPictureHeight,
                0,
                0,
                userPictureWidth,
                userPictureHeight
            );
        }
        image.src = dataUri;
        await image.decode();
        //get blob:
        function dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        }
        const blob = dataURLtoBlob(dataUri)
        //const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
        return { "userPicture": blob, "canvas": canvas, "imageUri": dataUri };
    }

    render() {
        const { videoRef } = this.state;
        return (
            <div style={{
                width: this.props.width,
                height: this.props.height,
                overflow: "hidden",
                transform: "rotateY(180deg)",
                maxHeight: "50vh",
                maxWidth: "90vw"
            }}>
                <video
                    playsInline
                    muted
                    autoPlay
                    ref={videoRef}
                    id="mivideo"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}
                />
            </div >
        );
    }
}